import React, { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';

const DownloadButton = () => {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const fetchFiles = async () => {
      const response = await fetch(`${process.env.PUBLIC_URL}/files.json`);
      const filesList = await response.json();
      setFiles(filesList);
    };

    fetchFiles();
  }, []);

  const handleDownload = async () => {
    const zip = new JSZip();
    const folder = zip.folder("demo");

    for (const file of files) {
      const response = await fetch(`${process.env.PUBLIC_URL}/downloads/${file}`);
      const blob = await response.blob();
      folder.file(file, blob);
    }

    const content = await zip.generateAsync({ type: "blob" });
    saveAs(content, "demo.zip");
  };

  return (
    <button className="download-button" onClick={handleDownload}>
      Download Demo Files
    </button>
  );
};

export default DownloadButton;