import React from 'react';

const Button = () => {
  const handleNavigation = () => {
    window.open('https://4c7a-193-191-137-219.ngrok-free.app/', '_blank', 'noopener,noreferrer');
  };

  return (
    <button className="button" onClick={handleNavigation}>
      Go to App
    </button>
  );
};

export default Button;