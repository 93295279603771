import React from 'react';
import './App.css';
import DownloadButton from './components/DownloadButton';
import Button from './components/Button';

const App = () => {
  return (
    <div className="App">
      <header className="App-header">
        <img className="App-header-img" src={`${process.env.PUBLIC_URL}/android-chrome-192x192.png`} classname="App-logo" alt="logo" />
        <div className="App-header-buttons">
          <a href="https://github.com/Dehayez/lyrikalempire" target="_blank" rel="noopener noreferrer">
            <img className="App-header-img App-header-img--github" src={`${process.env.PUBLIC_URL}/github-mark-white.png`} classname="App-logo" alt="github" />
          </a>
        </div>
      </header>
      <div className='content'>
        <div className='content-text'>
          <h1>Lyrikal Empire</h1>
          <p>The music tool for musical talents</p>
          <Button />
          <DownloadButton />
        </div>
      </div>
    </div>
  );
};

export default App;